:root {
  --gray-100: #444444;
  --gray-200: #555;
  --gray-300: #f1f1f1;
  --gray-400: #cccccc;
  --gray-500: #dddddd;
  --gray-600: #eee;
  --gray-800: #aaaaaa;
  --gray-900: #1d262d;
  --gray-1000: #717a86;

  --red: #f7115c;
  --blue: #03467b;
  --lightblue: #007bff;
  --green: #27c2b0;

  --facebook: #2f54a4;
  --twitter: #55acef;
  --instagram: #c32861;
  --youtube: #ce201f;
  --pinterest: #bd081b;

  --special: linear-gradient(261.31deg, #7425f4 -0.36%, #f52a8d 100.39%);
}

@font-face {
  font-family: "icomoon";
  src: url("assets/fonts/icomoon.eot");
  src: url("assets/fonts/icomoon.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/icomoon.ttf") format("truetype"),
    url("assets/fonts/icomoon.woff") format("woff"),
    url("assets/fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e91c";
}

.icon-lock:before {
  content: "\e91d";
}

.icon-menu:before {
  content: "\e91e";
}

.icon-pos:before {
  content: "\e917";
}

.icon-cobranzas:before {
  content: "\e918";
}

.icon-compras:before {
  content: "\e919";
}

.icon-config:before {
  content: "\e91a";
}

.icon-ventas:before {
  content: "\e91b";
}

.icon-despega:before {
  content: "\e913";
}

.icon-emprende:before {
  content: "\e914";
}

.icon-facturador:before {
  content: "\e915";
}

.icon-vuela:before {
  content: "\e916";
}

.icon-question:before {
  content: "\e912";
}

.icon-check:before {
  content: "\e90c";
}

.icon-facebook:before {
  content: "\e90d";
}

.icon-instagram:before {
  content: "\e90e";
}

.icon-pinterest:before {
  content: "\e90f";
}

.icon-twitter:before {
  content: "\e910";
}

.icon-youtube:before {
  content: "\e911";
}

.icon-iso:before {
  content: "\e903";
}

.icon-authorized:before {
  content: "\e900";
}

.icon-docs:before {
  content: "\e901";
}

.icon-interface:before {
  content: "\e902";
}

.icon-making:before {
  content: "\e904";
}

.icon-management:before {
  content: "\e905";
}

.icon-multiplatform:before {
  content: "\e906";
}

.icon-server:before {
  content: "\e907";
}

.icon-settings:before {
  content: "\e908";
}

.icon-speed:before {
  content: "\e909";
}

.icon-updates:before {
  content: "\e90a";
}

.icon-users:before {
  content: "\e90b";
}

body,
input,
textarea,
select {
  font-family: "Nunito", sans-serif;
}

body {
  font-size: 1rem;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
  padding-top: 80px;
  background-color: #ecfdff;
}

@media (min-width: 991px) {
  main {
    padding-top: 120px;
  }
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extrabold {
  font-weight: 800 !important;
}

.bg-facebook {
  background-color: var(--facebook);
}

.bg-twitter {
  background-color: var(--twitter);
}

.bg-instagram {
  background-color: var(--instagram);
}

.bg-youtube {
  background-color: var(--youtube);
}

.bg-pinterest {
  background-color: var(--pinterest);
}

strong {
  font-weight: 700 !important;
}

a {
  color: var(--gray-100);
  transition: all 0.3s ease;
}

a:hover {
  color: var(--black);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
.font-h1,
.font-h2,
.font-h3,
.font-h4,
.font-h5,
.font-h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

.font-h1 {
  font-size: 3rem;
}
.font-h2 {
  font-size: 2rem;
}
.font-h3 {
  font-size: 1.75rem;
}
.font-h4 {
  font-size: 1.5rem;
}
.font-h5 {
  font-size: 1.25rem;
}
.font-h6 {
  font-size: 0.938rem;
}
.font-h7 {
  font-size: 0.9rem;
}

.font-display {
  font-size: 3.5rem;
}

@media (min-width: 576px) {
  .font-sm-h1 {
    font-size: 3rem;
  }
  .font-sm-h2 {
    font-size: 2rem;
  }
  .font-sm-h3 {
    font-size: 1.75rem;
  }
  .font-sm-h4 {
    font-size: 1.5rem;
  }
  .font-sm-h5 {
    font-size: 1.25rem;
  }
  .font-sm-h6 {
    font-size: 0.938rem;
  }
  .font-sm-h7 {
    font-size: 0.9rem;
  }
}

@media (min-width: 768px) {
  .font-md-h1 {
    font-size: 3rem;
  }
  .font-md-h2 {
    font-size: 2rem;
  }
  .font-md-h3 {
    font-size: 1.75rem;
  }
  .font-md-h4 {
    font-size: 1.5rem;
  }
  .font-md-h5 {
    font-size: 1.25rem;
  }
  .font-md-h6 {
    font-size: 0.938rem;
  }
  .font-md-h7 {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {
  .font-lg-h1 {
    font-size: 3rem;
  }
  .font-lg-h2 {
    font-size: 2rem;
  }
  .font-lg-h3 {
    font-size: 1.75rem;
  }
  .font-lg-h4 {
    font-size: 1.5rem;
  }
  .font-lg-h5 {
    font-size: 1.25rem;
  }
  .font-lg-h6 {
    font-size: 0.938rem;
  }
  .font-lg-h7 {
    font-size: 0.9rem;
  }
}

@media (min-width: 1200px) {
  .font-xl-h1 {
    font-size: 3rem;
  }
  .font-xl-h2 {
    font-size: 2rem;
  }
  .font-xl-h3 {
    font-size: 1.75rem;
  }
  .font-xl-h4 {
    font-size: 1.5rem;
  }
  .font-xl-h5 {
    font-size: 1.25rem;
  }
  .font-xl-h6 {
    font-size: 0.938rem;
  }
  .font-xl-h7 {
    font-size: 0.9rem;
  }
}

.bg-red {
  background-color: var(--red);
}

.bg-blue {
  background-color: var(--blue);
}

.text-red {
  color: var(--red);
}

.text-blue {
  color: var(--blue);
}

.text-lightblue {
  color: var(--lightblue);
}

.btn {
  min-width: 220px;
  min-height: 45px;
  border-radius: 25px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-transform: uppercase;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
  font-weight: 600;
}

.btn-primary {
  border-color: var(--red) !important;
  background-color: var(--red) !important;
}

.btn-outline-primary {
  color: var(--red) !important;
  border-color: var(--red) !important;
  background-color: transparent;
}

.btn-outline-primary:hover {
  background-color: var(--red) !important;
  color: var(--white) !important;
}

.btn-secondary {
  color: var(--white) !important;
  border-color: var(--lightblue) !important;
  background-color: var(--lightblue) !important;
}

.btn-secondary:hover {
  color: var(--white) !important;
}

.btn-outline-secondary {
  color: var(--lightblue) !important;
  border-color: var(--lightblue) !important;
  background-color: transparent;
}

.btn-outline-secondary:hover {
  background-color: var(--lightblue) !important;
  color: var(--white) !important;
}

.btn-auto {
  min-width: inherit;
}

.none:hover {
  text-decoration: none;
}

.medium {
  font-size: 95%;
}

.small {
  font-size: 90%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

@media (min-width: 576px) {
  .w-sm-20 {
    width: 20% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }
}

@media (min-width: 768px) {
  .w-md-20 {
    width: 20% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-20 {
    width: 20% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }
}

.featured {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.display-5 {
  font-size: 3rem;
}

.list {
  margin-left: 15px;
}

.list li {
  margin: 7px 0;
}

.list li::before {
  font-family: "icomoon" !important;
  content: "\e90c";
  color: var(--green);
  margin-right: 5px;
}

.text-decoration-underline {
  text-decoration: underline;
}

.wrap {
  display: flex;
  flex-direction: column;
}

.wrap .content {
  flex: 1;
  padding-bottom: 2rem;
}

.border-red {
  border-color: var(--red) !important;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.emite {
  background: url("assets/images/stain.svg") center left no-repeat;
}

@media (min-width: 991px) {
  .main {
    background: url("assets/images/home.svg") center top no-repeat;
  }
}

.home {
  min-height: 750px;
}

.rounded {
  border-radius: 0.85rem !important;
}

.line-height-15 {
  line-height: 1.5;
}

.line-height-16 {
  line-height: 1.6;
}

.line-height-17 {
  line-height: 1.7;
}

.line-height-18 {
  line-height: 1.8;
}

.line-height-19 {
  line-height: 1.9;
}

.line-height-20 {
  line-height: 2;
}

footer {
  background-color: var(--gray-900);
  color: var(--gray-500);
}

footer a {
  color: var(--gray-500);
}

.icon-social {
  border-radius: 5px;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

@media (min-width: 991px) {
  .icon-social {
    width: 50px;
    height: 50px;
  }
}

.icon-social:hover {
  text-decoration: none;
}

.border-gray {
  border-color: var(--gray-100) !important;
}

.bg-special {
  background: var(--special);
}

.partners {
  border: 2px dashed var(--red);
}

.table {
  border-collapse: separate;
}

.table .plan {
  width: 150px;
  text-align: center;
}

.table th {
  padding: 15px;
}

.table td {
  border: 1px solid #ddd;
  background-color: var(--white);
  padding: 15px;
}

.table tr:nth-child(even) td {
  background-color: #f0f1f5;
}

.table td.description {
  background-color: #f3f4f8;
}

.table tr:nth-child(even) td.description {
  background-color: #e6e7eb;
}

.table td.heading {
  border: none;
  font-weight: 800;
  background-color: transparent !important;
  text-transform: uppercase;
}

.table .btn {
  background-color: var(--white);
}

.table td.description.none {
  background-color: transparent !important;
  border: none !important;
}

.btn-group-sm > .btn,
.btn-sm {
  min-height: inherit !important;
  padding: 0.5rem 1.25rem;
}

.prices {
  overflow: auto;
  padding-top: 50px;

  @media (min-width: 991px) {
    padding-top: 0;
    overflow: inherit;
  }
}

.prices .description {
  border: 1px solid transparent;
  width: 100%;
  margin-right: 5px;
  font-size: 95%;
  max-width: 50vw;
  padding: 20px 0;
  overflow: hidden;
  flex-shrink: 0;

  @media (max-width: 992px) {
    font-size: 85%;
    line-height: 1.1;
  }

  @media (min-width: 991px) {
    max-width: inherit;
    flex-shrink: inherit;
  }

  .h5 {
    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }

  > div {
    @media (min-width: 991px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.prices .item {
  border: 1px solid #ddd;
  background-color: white;
  width: 170px;
  flex-shrink: 0;
  text-align: center;
  margin: 0 5px;
  padding: 20px 0;
  border-radius: 10px;
  position: relative;
}

.prices .item.active,
.prices .item:hover {
  border: 1px solid var(--red);
  box-shadow: 0 0 7px #ff9dbd;
}

.prices .item.active .btn,
.prices .item:hover .btn {
  background-color: var(--red) !important;
  color: var(--white) !important;
}

.prices .item.active {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.prices .description > div,
.prices .item > div {
  padding: 5px 15px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.prices .description > div {
  padding-left: 0;
}

.prices .item > div {
  justify-content: center;
}

.prices .item:last-child {
  margin-right: 0;
}

.prices .plan {
  font-weight: bold;
  line-height: 1.25;
  background-color: transparent;
  height: 50px;
  text-transform: uppercase;
  font-size: 90%;
}

.prices .most {
  background-color: var(--red);
  color: var(--white);
  position: absolute;
  top: -50px;
  width: calc(100% + 2px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-transform: uppercase;
  margin-left: -1px;
  margin-right: 1px;
  font-size: 90%;
  font-weight: 600;
  min-height: 50px !important;
}

.prices .fa-check {
  color: var(--blue);
}

.hpx-58 {
  height: 58px;
}

.hpx-50 {
  height: 50px;
}

@media (min-width: 991px) {
  .w-c-25 {
    width: calc(25% - 1rem);
  }
}

@media (min-width: 991px) {
  .bg-mobile {
    background: url("assets/images/mobile.svg") center right no-repeat;
  }
}

@media (max-width: 992px) {
  .display-4 {
    font-size: 2.5rem;
  }
}

.menu-auto {
  overflow-x: auto;
  margin-bottom: 2.5rem;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 991px) {
  .menu-auto {
    display: none;
  }
}

.menu-tab {
  display: flex;
  align-items: center;
  font-size: 90%;
  width: 700px;
  position: relative;
}

.menu-wrap {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}

.menu-tab:hover {
  transform: inherit;
}

.menu-tab > div {
  width: 25%;
  border-left: 1px solid #ddd;
  border-bottom: 2px solid #ccc;
  text-align: center;
  padding: 1rem 2rem;
  scroll-snap-align: center;
  cursor: pointer;
}

.menu-tab > div:first-child {
  border-left: none;
}

.menu-tab > div.active {
  border-bottom-width: 3px;
  border-bottom-color: var(--red);
}

.tab-content {
  transition: border-color 2s ease;
}

.tab-content:hover {
  border-color: rgb(255, 157, 157) !important;
  box-shadow: 0 0 10px rgb(255, 190, 190) !important;
}

@media (min-width: 991px) {
  .responsive {
    display: block;
  }
}

.bgindex {
  animation: fade 10s infinite;
  position: absolute;
  top: -120px;
  right: -130px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.menu {
  cursor: pointer;

  &-contact {
    @media (max-width: 992px) {
      display: block;
      margin-top: 1rem;
      padding: 0 1rem;
    }

    a {
      display: block;
      font-size: 80%;
    }

    .icon {
      font-size: 90%;
      margin-right: 0.25rem;
    }
  }

  span {
    display: none;

    &.active {
      display: block;
    }
  }

  &-shadow {
    position: fixed;
    transition: all 0.2s ease-in-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 8;

    &.active {
      pointer-events: auto;
      background-color: rgba(59, 60, 83, 0.35);
    }
  }
}

.responsive {
  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 80vw;
    min-height: 100vh;
    z-index: 9;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    background-color: var(--white);
  }

  &.active {
    transform: translateX(0);
    box-shadow: 0 0 10px var(--gray-1000);
  }

  &-item {
    a {
      @media (max-width: 992px) {
        padding: 1rem;
        display: block;
      }
    }
  }
}

.imageDownload {
  cursor: pointer;
}
